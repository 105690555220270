import jwt from 'jwt-encode';

export const AdmissionTypeIn = 'ENTRY';
export const AdmissionTypeOut = 'EXIT';
export const AppModeOnline = 'LIVE';
export const AppModeOffline = 'OFFLINE';
export const AppModeEmergency = 'EMERGENCY';
export const ValidatorModeOn = 'VALIDATOR_ON';
export const ValidatorModeOff = 'VALIDATOR_OFF';
export const AllowEntry = 'Allow Entry';
export const AllowExit = 'Allow Exit';
export const ManualEntryTicket = 'TICKET';
export const ManualEntryTransaction = 'TRNASACTION';
export const ManualEntryBarcode = 'BARCODE';
export const ManualTicketUnused = 'Unused';
export const ScanCheckFalse = 0; // green
export const ScanCheckTrue = 1; // amber
export const ScanSuccess = 1;
export const ScanFail = 0;
export const StaffScannerUserRole = "STAFF";
export const StaffSuperVisorScannerUserRole = "STAFF_SUPERVISOR"
export const AdmissionRuleType = {
  SINGLE_ENTRY_EXIT: "SINGLE_ENTRY_EXIT",
  MULTIPLE_ENTRY_EXIT_APB: "MULTIPLE_ENTRY_EXIT_APB",
  MULTIPLE_ENTRY_EXIT_NOAPB: "MULTIPLE_ENTRY_EXIT_NOAPB",
};
export const GuestJWT = (secret) => jwt(
    {
    scannerProfileId: 'bmsguest',
    scannerUserId: 'bmsguest',
    loggedInFrom: 'bmspwa',
    iat: 1641010421,
    exp: 9031614021,
  },
    secret,
);
export const GuestJWTUser = 'bmspwa';
export const GuestJWTIat = 1641010421;
export const GuestJWTexp = 9031614021;
export var Validation = {
  barcodeId: null,
  barcode: null,
  admissionRuleId: null,
  scanCheck: 0,
  ticketAttribute: null,
  admissionCount: 1,
  appResponseColor: null,
};
